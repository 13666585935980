import Vue from 'vue'
import VueRouter from 'vue-router'
// 官网
import GwView from '../views/GwView.vue'
// 后台管理
import HtView from '../views/HtView.vue'

Vue.use(VueRouter)

const routes = [{
	// 官网
	path: '/',
	component: GwView,
	redirect: '/Home',
	children: [{ // 首页
		path: 'Home',
		component: () => import('@/views/newGw/Home.vue')
	}, { // 解决方案
		path: 'jjfa',
		component: () => import('@/views/newGw/jjfa.vue')
	}, { // 客户案例
		path: 'khal',
		component: () => import('@/views/newGw/khal.vue')
	}, { // 资讯
		path: 'zx',
		component: () => import('@/views/newGw/zx.vue')
	}, { // 合作共赢
		path: 'hzgy',
		component: () => import('@/views/newGw/hzgy.vue')
	},
	{  
		path: 'Guanyu',
		comment: () => import('@/views/newGw/Guanyu.vue')
	},
	{
		path: 'jicai',
		component: () => import('@/views/newGw/jicai.vue')
	},
	// 子页面--------------------------
	{ // 资讯动态详情文本
		path: 'htmltext',
		component: () => import('@/views/newGw/children/htmltext.vue')
	}, { // 产品与服务跳转详情-产品
		path: 'tabcp',
		component: () => import('@/views/newGw/children/tab_cp.vue')
	}, { // 产品与服务跳转详情-服务
		path: 'tabfw',
		component: () => import('@/views/newGw/children/tab_fw.vue')
	}
	]
},
// 后台管理
{
	path: '/Ht',
	name: 'ht',
	component: HtView,
	redirect: '/Ht/ht_categ',
	children: [{ // 分类管理
		path: 'ht_categ',
		component: () => import('@/views/newHt/ht_categ.vue')
	},
	// 一级菜单-----------------
	{ // 右快捷功能
		path: 'rightFun',
		component: () => import('@/views/newHt/rightFun.vue')
	}, { // 客户案例
		path: 'khal',
		component: () => import('@/views/newHt/khal.vue')
	}, { // 解决方案
		path: 'fangan',
		component: () => import('@/views/newHt/fangan.vue')
	},
	// 二级菜单-----------------
	{ // 首页 - 轮播图
		path: 'lunbo',
		component: () => import('@/views/newHt/home/lunbo.vue')
	}, { // 首页 - 证书
		path: 'zs',
		component: () => import('@/views/newHt/home/zhengshu.vue')
	}, { // 关于我们 - 顶部背景
		path: 'topBack',
		component: () => import('@/views/newHt/gywm/topBack.vue')
	}, { // 关于我们 - 公司简介
		path: 'jianjie',
		component: () => import('@/views/newHt/gywm/jianjie.vue')
	}, { // 关于我们 - 企业文化
		path: 'wenhua',
		component: () => import('@/views/newHt/gywm/wenhua.vue')
	}, { // 关于我们 - 联系我们
		path: 'lianxi',
		component: () => import('@/views/newHt/gywm/lianxi.vue')
	}, { // 关于我们 - 合作伙伴
		path: 'huoban',
		component: () => import('@/views/newHt/gywm/huoban.vue')
	}, { // 资讯动态 - 企业动态
		path: 'qiye', // zixun
		component: () => import('@/views/newHt/zxdt/qiye.vue')
	}, { // 资讯动态 - 编辑添加
		path: 'tinyHtml',
		component: () => import('@/views/newHt/zxdt/tinyHtml.vue')
	}, { // 产品与服务 - 产品
		path: 'cp',
		component: () => import('@/views/newHt/cpyfws/cp.vue')
	}, { // 产品与服务 - 服务
		path: 'fw',
		component: () => import('@/views/newHt/cpyfws/fw.vue')
	}, { // 合作共赢- 合作申请
		path: 'hzsq',
		component: () => import('@/views/newHt/hzgy/hzsq.vue')
	}, { // 合作共赢- 扶持计划
		path: 'fcjh',
		component: () => import('@/views/newHt/hzgy/fcjh.vue')
	}, { // 合作共赢- 合作条件
		path: 'hztj',
		component: () => import('@/views/newHt/hzgy/hztj.vue')
	}, { // 合作共赢- 申请流程
		path: 'sqlc',
		component: () => import('@/views/newHt/hzgy/sqlc.vue')
	}


	]
},
// 注册
{
	path: '/signUp',
	component: () => import('@/views/signUp.vue')
}
]

const router = new VueRouter({
	routes,
	// 刷新页面返回顶部
	scrollBehavior(to, from, savedPosition) {
		return {
			x: 0,
			y: 0
		}
	}

})

export default router