<template>
	<div>
		<div class="headBox">
			<img src="@/assets/logo.png" alt="" @click="jump()">
			<!-- 电脑端显示 -->
			<div class="tab">
				<div v-for="(item,index) in tabList" :key="index" class="tabName"
				:style="{'color': $route.path==item.page?'#3d8bff':''}">
					<!-- 点击 -->
					<div @click="clickTab(item)">{{item.name}}</div>
					<svg class="icon" aria-hidden="true" v-if="!item.page" style="font-size: 0.15rem;margin-left: 0.05rem;">
						<use xlink:href="#icon-xiangxiajiantou"></use>
					</svg>
					<!-- 展示 -->
					<div class="topShow" v-if="!item.page">
						<!-- 鼠标悬浮展开-产品服务 -->
						<topShow></topShow>
					</div>
				</div>
				<div class="tabName"> 
					<div class="button" @click="c_zhuce">申请免费试用></div>
				</div>
				
			</div>
			<!-- 手机端显示 -->
			<div class="right">
				<div class="switch" @click="drawer = true">
					<!-- 展开图标 -->
					<svg class="icon" aria-hidden="true">
						<use xlink:href="#icon-zhankai"></use>
					</svg>
				</div>
			</div>
		</div>
		<!-- 手机端抽屉 -->
		<el-drawer :visible.sync="drawer" size="50%" :modal-append-to-body="false" direction="rtl"
		 style="font-size: 0.25rem;">
			 <template slot="title">
				 <div style="width: 100%;">
					 <div class="tabName" v-show="$route.path != '/signUp'">
					 	<div class="button" @click="c_zhuce">申请免费试用></div>
					 </div>
				 </div>
			 </template>
			<div :style="{'color': $route.path==item.page?'#3d8bff':''}" v-for="(item,index) in tabList" :key="index"
			class="drawer" @click="clickTab(item)" style="z-index: 300;">
				{{item.name}}
			</div>
		</el-drawer>
		<!-- 手机端-产品服务下拉弹窗 -->
		<el-drawer :visible.sync="ydTop_show" size="80%" :modal-append-to-body="false" direction="ttb">
			<topShow @c_tab="c_tab"></topShow>
		</el-drawer>

		<!-- 返回顶部 -->
		<svg class="icon gotop" aria-hidden="true" v-if="gotopShow" @click="c_toTop()">
			<use xlink:href="#icon-fanhuidingbu"></use>
		</svg>
		

	</div>
</template>

<script>
	import {
		apiUrl
	} from "@/public/apiUrl.js"
	
	import topShow from "./topShow.vue"
	export default {
		components: {
			topShow
		},
		data() {
			return {
				tabList: [{
						name: '首页',
						page: '/Home'
					}
					,
					{
						name: '供应链平台',
						page: '/jicai'			
					},
					
					{
						name: '产品与服务',
						// page: '/Cpfw'
					},
					{
						name: '解决方案',
						page: '/jjfa'
					},
					{
						name: '客户案例',
						page: '/khal'
					},
					{
						name: '资讯动态',
						page: '/zx'
					},
					{
						name: '合作共赢',
						page: '/hzgy'
					},
					{
						name: '关于我们',
						page: '/Guanyu'
					}
					// {
					// 	name: '购买服务',
					// 	page: '/Goumai'
					// },
					// {
					// 	name: '下载',
					// 	page: '/Xiaz'
					// },
				],
				drawer: false,
				time: '',
				timeNum: 0,
				gotopShow: false,
				ydTop_show: false
			}
		},
		created() {
		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll, true);
		},
		methods: {
			c_zhuce(){
				this.$router.push('signUp')
			},
			// 产品服务tab关闭
			c_tab(){
				// 关闭产品服务弹窗
				this.ydTop_show = false
				// 关闭侧边栏弹窗
				this.drawer = false
			},
			handleScroll() {
				let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
				scrolltop > 30 ? (this.gotopShow = true) : (this.gotopShow = false);
			},
			// 返回顶部
			c_toTop() {
				let top = document.documentElement.scrollTop || document.body.scrollTop;
				// 实现滚动效果 
				const timeTop = setInterval(() => {
					document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
					if (top <= 0) {
						clearInterval(timeTop);
					}
				}, 10);
			},
			clickTab(item) {
				// console.log(item)
				// 没有跳转页面 且 是PC端时 弹出抽屉
				if (!item.page && this.$store.state.equipment != "PC") {
					this.ydTop_show = true
					return
				}
				// console.log(item)
				this.drawer = false
				if (this.$route.path == item.page || !item.page) return
				this.$router.push(item.page)
			},
			// 连续点击5次
			jump() {
				this.timeNum++
				clearTimeout(this.time)
				this.time = setTimeout(() => {
					this.timeNum = 0
				}, 500)
				if (this.timeNum == 5) {
					// window.open('/#/ht');
					window.open('/Wx/index');
				}
				if (this.timeNum == 1 && this.$route.path != '/Home') {
					this.$router.push('/')
				}
			},

		}
	}
</script>

<style scoped lang="less">
	a {
		text-decoration: none;
	}
	// 返回顶部
	.gotop {
		font-size: 0.5rem;
		color: #fff;
		cursor: pointer;
		position: fixed;
		bottom: 13%;
		right: 8%;
		border-radius: 50%;
		padding: 0.05rem;
		background-color: #4f7cfc;
		// 禁止用户复制
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	// 手机端
	@media(max-width: 992px) {
		/deep/ #el-drawer__title{
			margin-bottom: 0.2rem !important;
		}
		.gotop {
			width: 30px;
			height: 30px;
		}
		
		.tab,
		.jump {
			display: none !important;
		}

		.headBox {
			height: 1rem;
			width: 6.8rem;
			margin: auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			// border: 1px solid #ff0000;
			font-size: 0.25rem;
			color: #73757a;

			img {
				height: 0.5rem;
			}

			.right {
				display: flex;
				align-items: center;

				.acc {
					margin-right: 0.2rem;
					font-size: 0.25rem;
				}
				.switch {
					width: 0.9rem;
					line-height: 0.5rem;
					text-align: center;
					// border: 1px solid #cfcfcf;
					border-radius: 0.04rem;
					box-shadow: 0 0 0.1rem 0.03rem rgba(0, 0, 0, 0.15);
				}
			}

		}
		// 免费试用
		.tabName{
			border: 1px solid #0082e7;
			border-radius: 0.15rem;
			margin-right: 0.2rem;
			padding: 0.1rem 0.2rem;
			color: #0082e7;
		}
		.drawer {
			padding: 0.3rem 0.5rem;
			border-bottom: 1px solid #b6b6b6;
			color: #64666a;
		}

		.drawer:nth-child(1) {
			margin-top: 0;
		}
	}

	// pc端
	@media(min-width:993px) {

		.headBox {
			height: 8vh;
			width: 14rem;
			margin: auto;
			display: flex;
			justify-content: space-between;
			align-items: center;

			// border: 1px solid #ff0000;
			.tab {
				display: flex;
				// margin-right: 10%;
				height: 100%;

				.tabName {
					margin-left: 0.3rem;
					font-size: 0.18rem;
					white-space: nowrap;
					// border: 1px solid #ff0000;
					height: 100%;
					display: flex;
					align-items: center;
				}
				.button{
					border: 0.03rem solid #1f76df;
					padding: 0.1rem 0.2rem;
					border-radius: 0.5rem;
					color: #1f76df;
					font-size: 0.15rem;
				}

				// 组件展示
				.topShow {
					display: none;
					position: absolute;
					top: 8vh;
					left: 0;
					right: 0;
					background-color: #fff;
					// border: 1px solid #ff0000;
				}

				.tabName:hover {
					cursor: pointer;
					color: #0055ff;

					.topShow {
						display: block;
						box-shadow: 0 0 0.3rem 0 rgba(43, 107, 227, 0.3);
					}
				}
			}

			.jump {
				position: absolute;
				right: 2rem;
			}
		}

		.right {
			display: none !important;
		}

	}

	


</style>