<template>
	<div style="display: flex;flex-direction: column;">
		
		<div class="tail">
			<!-- pc端 -->
			<div class="tailBox tailBoxPC">
				<div class="columns">
					<div class="title">产品服务：</div>
					<div v-for="item in get_data.cp" class="hover" @click="c_tab(item,'cp')">{{item.contentShow.title}}</div>
					<div v-for="item in get_data.fw" class="hover" @click="c_tab(item,'fw')">{{item.contentShow.title}}</div>
				</div>
				<div class="columns">
					<div class="title">行业案例：</div>
					<div v-for="item in get_data.jjfa" class="hover" @click="c_tab(item,'jjfa')">{{item.contentShow.title}}</div>
				</div>
				
				<div class="columns">
					<div class="title">关于我们：</div>
					<div class="hover" @click="c_jump()">公司简介</div>
					<div class="hover" @click="c_jump()">企业文化</div>
					<div class="hover" @click="c_jump()">战略合作</div>
				</div>
				<div class="columns">
					<div class="title">联系我们：</div>
					<div>业务咨询：400-012-6229</div>
					<div>浙江省杭州市萧山区萧山国际商务中心2幢2406</div>
				</div>
				<img src="../assets/btn.png">
			</div>
			<!-- 手机端 -->
			<div class="tailBox2 tailBoxYD" >
				<div class="Box2">
					<div class="Box">
						<div class="columns">
							<div class="title">产品服务：</div>
							<div v-for="item in get_data.cp" class="hover" @click="c_tab(item,'cp')">{{item.contentShow.title}}</div>
							<div v-for="item in get_data.fw" class="hover" @click="c_tab(item,'fw')">{{item.contentShow.title}}</div>
						</div>
						<div class="columns">
							<div class="title">行业案例：</div>
							<div v-for="(item,index) in get_data.jjfa" :key="index" class="hover" @click="c_tab(item,'jjfa',index)">{{item.contentShow.title}}</div>
						</div>
						
						<div class="columns" @click="">
							<div class="title">关于我们</div>
							<div>公司介绍</div>
							<div>招商招聘</div>
							<div>合作伙伴</div>
						</div>
					</div>
					<img src="../assets/btn.png">
				</div>
				
				<div class="columns4">
					联系我们：业务咨询：400-012-6229 </br>浙江省杭州市萧山区萧山国际商务中心2幢2406
				</div>
			</div>
			
		</div>
		<div class="bottomBox">
			浙ICP备20016250号-1
		</div>
		
	</div>
</template>

<script>
	import api from "@/public/api.js"
	export default {
		components:{},
		data() {
			return {
				get_data: {
					cp:[],// 产品
					fw:[],// 服务
					jjfa:[]// 解决方案
				}
			}
		},
		created() {
			this.api_getData('1376341', 'cp')
			this.api_getData('1376967', 'fw')
			this.api_getData('1374098', 'jjfa')
		},
		methods:{
			// 跳转至关于店开
			c_jump(){
				if(this.$route.path != '/Guanyu') {
					this.$router.push('Guanyu')
				}else{
					// 在当前页的话返回顶部
					this.c_toTop()
				}
			},
			// 产品,服务,解决方案
			c_tab(item,type='',index=0) {
				// 跳转至解决方案
				if(type == 'jjfa'){
					if(!(this.$route.path == '/jjfa')){
						this.$router.push({
							// 产品：tabdetails，服务：tabfw
							path: 'jjfa',
							query: {
								data: this.$tools.encode64(item.contentShow),
								index: index // 手机端需要传入index，用于手机端tab栏选中
							}
						});
					}
					this.c_toTop()
					return
				}
				// 跳转至产品 与 服务
				sessionStorage.setItem('tabData',JSON.stringify(item.contentShow))
				let path = (type=='cp'?"/tabcp":'/tabfw')
				if(this.$route.path == path){
					// 如果是当前页面刷新数据不跳转
					this.$router.go(0)
				}else{
					this.$router.push(path)
				}
				this.c_toTop()
			},
			api_getData(categ_id, name) {
				api({
					url: '/Api/get',
					data: {
						categ_id: categ_id
					}
				}, true).then(res => {
					res.map(item => {
						item.contentShow = this.$tools.decode64(item.content)
					})
					if(name == 'cp' || name == 'fw') this.get_data[name] = res.slice(0,2)
					if(name == 'jjfa') this.get_data[name] = res.slice(0,4)
					// console.log(this.get_data[name])
				})
			},
			// 返回顶部
			c_toTop() {
				let top = document.documentElement.scrollTop || document.body.scrollTop;
				// 实现滚动效果 
				const timeTop = setInterval(() => {
					document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
					if (top <= 0) {
						clearInterval(timeTop);
					}
				}, 10);
			},
		}
	}
</script>

<style scoped lang="less">
	.hover:hover{
		cursor: pointer;
	}
	.bottomBox{
		text-align: center;
		font-size: 12px;
		color: #fff;
		background-color: #414754;
		margin-bottom: 10px;
	}
	// 手机端
	@media (max-width:992px){
		.tailBoxPC{
			display: none;
		}
		.tail{
			padding: 0.3rem 0;
			font-size: 0.2rem;
			// border: 1px solid #ff0000;
		}
		.tailBox2{
			// border: 1px solid #ff0000;
			// width: 6.5rem;
			
			margin: auto;
			.Box2 {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
				// border: 1px solid #00ff00;
				margin: 0 0.65rem;
			}
			.Box{
				width: 6rem;
				display: flex;
				flex-wrap: wrap;
				// border: 1px solid #aa007f;
				justify-content: space-between;
				margin: auto;
				margin-bottom: 0.2rem;
				.columns{
					// margin-right: 0.2rem;
					// border: 1px solid #0055ff;
					
					div{
						font-weight: 300;
						margin-top: 0.1rem;
					}
					.title{
						margin-top: 0;
						margin-bottom: 0.2rem;
						font-weight: 500;
					}
				}
			}
			
			img{
				width: 1.5rem;
				height: 1.5rem;
				margin: auto;
			}
			
			.columns4{
				text-align: center;
				// text-align: right;
				margin-top: 0.4rem;
				// border: 1px solid #aa007f;
			}
		}
	}
	// pc端
	@media (min-width: 993px){
		.tailBoxYD{
			display: none;
		}
		.tail{
			height: 3.5rem;
			display: flex;
			font-size: 0.15rem;
		}
		.tailBox{
			width: 13rem;
			margin: auto;
			// border: 1px solid #ff0000;
			display: flex;
			justify-content: space-between;
			.columns{
				div{
					margin-bottom: 0.15rem;
					white-space: nowrap;
				}
				.title{
					margin-bottom: 0.2rem;
				}
			}
			
			img{
				width: 2rem;
				height: 2rem;
				border-radius: 0.04rem;
			}
		}
	}

</style>