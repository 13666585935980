import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import tools from '@/public/tools'
// 引入vantUI（按需引入组件）
import '../public/vant.js'

Vue.config.productionTip = false
Vue.prototype.$base64 = require('js-base64').Base64
// console.log(this.$base64.encode('我是我'))
// console.log(this.$base64.decode('5oiR5piv5oiR'))
Vue.prototype.$tools = tools

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
