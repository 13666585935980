<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>
	import "../src/public/font/iconfont.js"
	export default {
		data() {
			return {

			}
		},
		mounted() {
			// 获取设备
			this.getEqui()
			window.addEventListener('resize', this.getEqui, true)

			if (/iPhone|iPad|iPod|iOS/i.test(navigator.userAgent)) {
				// console.log("是iOS客户端");
				this.getRem(720, 100)
			} else if (/Android/i.test(navigator.userAgent)) {
				// console.log("是Android客户端");
				this.getRem(720, 100)
			} else {
				// console.log("是PC浏览器");
				this.getRem(1920, 100)
			}
		},
		methods: {
			getRem(pwidth, prem) {
				var html = document.getElementsByTagName("html")[0];
				var body = document.getElementsByTagName("body")[0];
				var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
				let setRem = oWidth / pwidth * prem + "px";
				html.style.fontSize = setRem;
				// 设置默认字体大小
				body.style.fontSize = "0.16rem";
			},
			getEqui() {
				if (/iPhone|iPad|iPod|iOS/i.test(navigator.userAgent)) {
					this.$store.commit('setEqui', 'iOS')
				} else if (/Android/i.test(navigator.userAgent)) {
					this.$store.commit('setEqui', 'Android')
				} else {
					this.$store.commit('setEqui', 'PC')
				}
			}
		}
	}
</script>

<style>
	pre{
		/* 微软雅黑 */
		font-family: Microsoft Yahei;
	}
	* {
		margin: 0;
		padding: 0;
	}
	li {
		list-style: none;
	}
	/* 禁止显示-滚动条 */
	html::-webkit-scrollbar {
		display: none;
	}
	/* 侧边栏 */
	.aside::-webkit-scrollbar {
		display: none;
	}
	/* 后台内容侧边栏 */
	.el-dialog__wrapper::-webkit-scrollbar {
		display: none;
	}
	/* 解决方案 左边侧边栏 */
	.tabTitle::-webkit-scrollbar {
		display: none;
	}
	/* 解决方案 内容侧边栏 */
	.funList::-webkit-scrollbar {
		display: none;
	}
	
	
	/* 阿里图标Symbol */
	.icon {
	  width: 1em;
	  height: 1em;
	  vertical-align: -0.15em;
	  fill: currentColor;
	  overflow: hidden;
	}
	/* Element组件 $message 提示图标的大小 */
	/* .el-icon-info,.el-icon-success{
		font-size: 20px;
	} */
	/* Element组件 el-dialog 弹出层标题，底部大小 */
	/* .el-dialog__header{
		font-size: 0;
	}
	.dialog-footer{
		font-size: 0;
	} */
</style>