<template>
	<div>

		<Head class="head"></Head>
		<!-- 轮播图 -->
		<lunbo></lunbo>
		<!-- 切换内容 -->
		<router-view></router-view>
		<!-- 切换内容 -->
		<Tail style="background-color: #414754;color: #fff;"></Tail>
		
	</div>
</template>

<script>
	import Head from "@/components/Head.vue"
	import Tail from "@/components/Tail.vue"
	import lunbo from "@/components/lunbo"
	export default {
		components: {
			Head,
			Tail,
			lunbo
		},
		data() {
			return {
			}
		},
		created() {
			
		},
		methods: {

		}
	}
</script>


<style scoped lang="less">
	.head {
		position: sticky;
		top: 0;
		left: 0;
		background-color: #fff;
		z-index: 200;
		// border: 1px solid #0055ff;
	}
	
	
	
</style>