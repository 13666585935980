<template>
	<div>
		<el-container>
			<!-- 侧边导航 -->
			<div class="aside aside_PC">
				<div class="asideImgBox">
					<img src="../assets/logo.png" alt="">
				</div>
				<el-menu :default-active="getPageUrl" class="asideMenu" :router="true" 
				 active-text-color="#1b64dd" text-color="#767676">
					<el-menu-item index="ht_categ">
						<i class="el-icon-menu"></i>
						<span slot="title">类别管理</span>
					</el-menu-item>
					

					<div v-for="(item,index) in categ_List" :key="index">
						<el-submenu :index="index+''" v-if="item.children.length > 0">
							<template slot="title">
								<i class="el-icon-menu"></i>
								<span slot="title">{{item.categ_name}}</span>
							</template>
							<el-menu-item :index="item2.url+'?categ_id='+item2.categ_id" style="min-width: auto;"
								v-for="(item2,index2) in item.children" :key="index2">
								{{item2.categ_name}}
							</el-menu-item>
						</el-submenu>
						<!-- 不存在子页面 -->
						<el-menu-item :index="item.url+'?categ_id='+item.categ_id" v-else>
							<i class="el-icon-menu"></i>
							<span slot="title">{{item.categ_name}}</span>
						</el-menu-item>
					</div>

				</el-menu>

			</div>
			<el-container>
				<!-- 头部 -->
				<el-header style="display: flex;justify-content: space-between;align-items: center;padding: 0 0.2rem;background-color: #ffffff;">
					<div>
						<div class="yid_title" @click="$router.push('/')">店开官网后台</div>
					</div>
					<div style="display: flex;">
						<div style="display: flex;align-items: center;">
							<i class="el-icon-refresh buttoni" @click="updata()" style="font-weight: bold;font-size: 2.5vh;color: #000;"></i>
						</div>
					</div>
				</el-header>
				<!-- 内容 -->
				<el-main style="height: 92vh;background-color: #f3f3f3;">
					<router-view style="margin: 0.2rem;background-color: #fff;padding: 0.2rem;border-radius: 5px;"></router-view>
				</el-main>

			</el-container>
		</el-container>
		
		<div class="rightButt" @click="drawer = !drawer">
			<!-- 展开图标 -->
			<svg class="icon" aria-hidden="true">
			  <use xlink:href="#icon-zhankai"></use>
			</svg>
		</div>
		
		<el-drawer :visible.sync="drawer" direction="ltr" size="50%" :withHeader="false">
			<!-- 侧边导航 -->
			<div class="aside" style="width: 100%;">
				<div class="asideImgBox">
					<img src="../assets/logo.png" alt="">
				</div>
				<el-menu :default-active="getPageUrl" class="asideMenu" background-color="#fff" text-color="#00557f"
					:router="true" @select="drawer = false">
			
					<el-menu-item index="ht_categ">
						<i class="el-icon-menu"></i>
						<span slot="title">类别管理</span>
					</el-menu-item>
			
					<div v-for="(item,index) in categ_List" :key="index">
						<el-submenu :index="index+''" v-if="item.children.length > 0">
							<template slot="title">
								<i class="el-icon-menu"></i>
								<span slot="title">{{item.categ_name}}</span>
							</template>
							<el-menu-item :index="item2.url+'?categ_id='+item2.categ_id" style="min-width: auto;"
								v-for="(item2,index2) in item.children" :key="index2">
								{{item2.categ_name}}
							</el-menu-item>
						</el-submenu>
						<!-- 不存在子页面 -->
						<el-menu-item :index="item.url+'?categ_id='+item.categ_id" v-else>
							<i class="el-icon-menu"></i>
							<span slot="title">{{item.categ_name}}</span>
						</el-menu-item>
					</div>
			
				</el-menu>
			
			</div>
		</el-drawer>

	</div>
</template>

<script>
	import api from "../public/api.js"
	export default {
		data() {
			return {
				categ_List: [],
				drawer: false
			}
		},
		created() {
			api({
				url: '/Categ/Menu'
			}, true).then(res => {
				// console.log('列表',res)
				this.categ_List = res
			})
		},
		methods: {
			updata() {
				// console.log('刷新')
				this.$router.go(0)
			},
		},
		computed: {
			// 页面路径
			getPageUrl() {
				let url = window.location.hash.split('/')
				return url[url.length - 1]
			}
		}
	}
</script>

<style scoped lang="less">
	// 移动端
	@media(max-width: 992px){
		.aside_PC{
			display: none;
		}
		.yid_title{
			font-size: 20px;
		}
		.aside{
			// border: 1px solid #ff0000;
			height: 100% !important;
			.asideImgBox{
				position: sticky;
				top: 0;
				z-index: 20;
				background-color: #fff;
			}
		}
		.rightButt{
			position: fixed;
			top: 85%;
			left: 5%;
			background-color: rgba(0, 0, 0, 0.5);
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			z-index: 200;
			.icon {
			  width: 1.5em;
			  height: 1.5em;
			  vertical-align: -0.15em;
			  fill: currentColor;
			  overflow: hidden;
			  color: #fff;
			}
		}
	}
	// pc端
	@media(min-width: 993px){
		.rightButt{
			display: none;
		}
		.yid_title{
			display: none;
		}
	}
	.buttoni {
		font-size: 18px;
		color: #fff;
	}
	
	.buttoni:hover {
		cursor: pointer;
	}
	.el-header,
	.el-footer {
		height: 8vh !important;
		padding: 0;
		
	}

	.aside {
		background-color: #fff;
		color: #333;
		height: 100vh;
		overflow: auto;
		width: 190px;

		.asideImgBox {
			height: 8vh;
			display: flex;
			align-items: center;
			border-bottom: 1px solid rgba(145, 145, 145, 0.2);
			position: sticky;
			top: 0;
			z-index: 200;
			background-color: #fff;
			img {
				width: 150px;
				height: 40px;
				margin: 0 20px;
				
			}
		}

		.asideMenu {
			font-weight: bold;
			border: none;
		}
	}

	.el-main {
		padding: 0;
	}
</style>