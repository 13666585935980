<template>
	<div>
		<div class="box">


			<div class="xtBox">
				<div class="title">数字化产品</div>
				<div class="info">
					
					<div v-for="item in get_data.cp" class="forBox" @click="c_tab(item,'cp')">
						<img :src="item.contentShow.icon" alt="" class="forBox_img">
						<div class="forBox_right">
							<div class="forBoxTitle">{{item.contentShow.title}}</div>
							<div class="textBox">{{item.contentShow.info}}</div>
						</div>
					</div>

				</div>
			</div>

			<div class="xtBox">
				<div class="title">服务</div>
				<div class="info">
					
					<div v-for="item in get_data.fw" class="forBox" @click="c_tab(item,'fw')">
						<img :src="item.contentShow.icon" alt="" class="forBox_img">
						<div class="forBox_right">
							<div class="forBoxTitle">{{item.contentShow.title}}</div>
							<div class="textBox">{{item.contentShow.info}}</div>
						</div>
					</div>

				</div>
			</div>


		</div>
	</div>
</template>

<script>
	import api from "@/public/api.js"
	export default {
		components: {},
		props: [],
		data() {
			return {
				get_data: {
					cp: [],
					fw: []
				}
			}
		},
		created() {
			// 产品与服务
			this.api_getData('1376341', 'cp')
			this.api_getData('1376967', 'fw')
			// console.log(this.get_data)
		},
		methods: {
			api_getData(categ_id, name) {
				api({
					url: '/Api/get',
					data: {
						categ_id: categ_id
					}
				}, true).then(res => {
					res.map(item => {
						item.contentShow = this.$tools.decode64(item.content)
					})
					this.get_data[name] = res
				})
			},
			c_tab(item,type) {
				// console.log('点击',item.contentShow)
				// 产品：tabdetails，服务：tabfw
				sessionStorage.setItem('tabData',JSON.stringify(item.contentShow))
				let path = (type=='cp'?"/tabcp":'/tabfw')
				if(this.$route.path == path){
					// 如果是当前页面刷新数据不跳转
					this.$router.go(0)
				}else{
					this.$router.push(path)
				}
				// 关闭顶部 产品与服务 弹窗
				let topShow = document.getElementsByClassName('topShow')[0]
				topShow.setAttribute('style', 'display: none;')
				setTimeout(()=>{
					topShow.setAttribute('style', 'display: ;')
				},200)
				// 关闭手机端 产品服务;右侧边栏 弹窗
				this.$emit('c_tab')
				
			},
			
		}
	}
</script>

<style scoped lang="less">
	.box {
		padding: 0.5rem 0;
		z-index: 20;
		width: 14rem;
		margin: auto;
		// border: 1px solid #0055ff;
		color: #000;
		display: flex;
	
		.xtBox {
			// padding-right: 1rem;
			margin-right: 1rem;
			border-right: 1px solid #c5c5c5;
			width: 35%;
			// border: 1px solid #ff0000;
			.title {
				font-size: 0.15rem;
				font-weight: bold;
			}
	
			.info {
				margin-top: 0.1rem;
				// border: 1px solid #00aa00;
				max-height: 50vh;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				overflow: auto;
				
				
				.forBox {
					display: flex;
					align-items: center;
					margin-left: 0.2rem;
					margin-top: 0.1rem;
	
					.forBox_img {
						width: 0.4rem;
						height: 0.4rem;
						border-radius: 0.05rem;
						// border: 1px solid #ff0000;
						margin-right: 0.1rem;
					}
	
					.forBox_right {
						margin: 0.05rem 0;
						.forBoxTitle{
							font-size: 0.15rem;
							line-height: 0.25rem;
							color: #000;
						}
						.textBox {
							white-space:normal;
							overflow : hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
							
							color: #a5a5a5;
							// margin-top: 0.03rem;
							font-size: 0.13rem;
						}
					}
	
					.forBox_right>.forBoxTitle:hover {
						color: #0055ff !important;
					}
				}
			}
		}
	
		.xtBox:nth-child(2) {
			border: none;
		}
	}
	
	// 移动端
	@media(max-width: 992px) {
		.box{
			width: 90%;
			flex-direction: column;
			padding: 0;
			.xtBox{
				width: 100%;
				// border: 1px solid #ff0000;
				padding-right: 0;
				margin-right: 0;
				border: none;
				.title{
					font-size: 0.35rem;
				}
				.forBox_img{
					width: 0.65rem !important;
					height: 0.65rem !important;
					margin-right: 0.2rem !important;
					// border: 1px solid #ff0000;
				}
				.forBox_right{
					.forBoxTitle{
						font-size: 0.28rem !important;
						line-height: 0.6rem !important;
					}
					.textBox{
						font-size: 0.2rem !important;
					}
				}
			}
			.xtBox:nth-child(2) {
				margin-top: 0.2rem;
			}
		}
	}

	// pc端
	@media(min-width: 993px) {}

</style>