<template>
	<div>
		<!-- 轮播图-pc -->
		<el-carousel :height="carouselHeight+'px'" style="position: relative;" class="show_pc">
			<el-carousel-item v-for="(item,index) in get_data.lunbo" :key="index">
				<img :src="item.contentShow.img" style="height: 100%;">
				<div class="carousel_btn" v-if="item.contentShow.url" @click="jump(item.contentShow.url)">
					了解详情
				</div>
			</el-carousel-item>
		</el-carousel>
		<!-- 轮播图-移动 -->
		<van-swipe class="my-swipe show_phone" :autoplay="5000" indicator-color="white" style="position: relative;">
			<van-swipe-item v-for="(item,index) in get_data.lunbo" :key="index">
				<img :src="item.contentShow.img" style="width: 100%;">
				<div class="carousel_btn" v-if="item.contentShow.url" @click="jump(item.contentShow.url)">
					了解详情
				</div>
			</van-swipe-item>
		</van-swipe>


	</div>
</template>

<script>
	import api from "@/public/api.js"
	export default {
		components: {},
		props: [],
		data() {
			return {
				get_data: {
					lunbo: ""
				},
				carouselHeight: '',
			}
		},
		created() {
			this.api_getData('1359883', 'lunbo')
			// 获取轮播图高度
			this.page_height()
		},
		destroyed() {
			window.removeEventListener('resize', this.page_height, true);
		},
		mounted() {
			window.addEventListener('resize', this.page_height, true)
		},
		methods: {
			jump(url) {
				window.open(url)
			},
			api_getData(categ_id, name) {
				api({
					url: '/Api/get',
					data: {
						categ_id: categ_id
					}
				}, true).then(res => {
					res.map(item => {
						item.contentShow = this.$tools.decode64(item.content)
					})
					if (name == 'cert') {
						let b = []
						res.map((item, index) => {
							if (index % 2) {
								b.push({
									img1: res[index - 1].contentShow,
									img2: item.contentShow
								})
							}
						})
						this.get_data[name] = b
						return
					}
					this.get_data[name] = res
				})
			},
			// 轮播图高度
			page_height() {
				// console.log('page_height',this.carouselHeight)
				this.carouselHeight = window.innerWidth / 3.33
			},
		}
	}
</script>

<style scoped lang="less">
	// .my-swipe .van-swipe-item {
	//     color: #fff;
	//     font-size: 20px;
	//     line-height: 150px;
	//     text-align: center;
	//     background-color: #39a9ed;
	//   }
	// 移动端
	@media(max-width: 992px) {
		.show_pc{
			display: none;
		}
		.carousel_btn {
			font-size: 10% !important;
		}
	}

	// pc端
	@media(min-width: 993px) {
		.show_phone{
			display: none;
		}
	}

	// 轮播图
	.carousel_btn {
		padding: 1% 5%;
		text-align: center;
		position: absolute;
		z-index: 20;
		top: 60%;
		left: 18%;
		font-size: 20px;
		color: #ff0000;
		background: #ffffff;
		border-radius: 50px;
		box-shadow: 0 0.04rem 0.06rem 0.01rem rgba(0, 73, 223, 1);
		cursor: pointer;
	}
</style>