let Base64 = require('js-base64').Base64
/**
 * Base64 编码
 */
function encode64(data) {
	let data2 = JSON.stringify(data)
	return Base64.encode(data2)
}
/**
 * Base64 解码
 */
function decode64(data, type = false) {
	let base64Decode = Base64.decode(data)
	// 把json转换回原来类型（但还是字符串）
	let jsonPar = JSON.parse(base64Decode)
	// 有空格去除空格
	let remove = jsonPar //.replace(/\s*/g,"")
	// 1.在页面显示
	if (!type) return remove
	// 2.在输入框显示 需要再次转换一次 还原成数组 或 对象
	if (type) return eval('(' + remove + ')')
}
/** 获取当前时间
 * @param {Object} type 类型 all YMD hms
 * @param {Object} f 符号 - / 默认使用 -
 */
function getDateTime(type = 'all', f = '-') {
	function addZero(s) {
		// 小于10在前方拼接0
		return s < 10 ? ('0' + s) : s;
	}
	let date = new Date();
	//年 getFullYear()：四位数字返回年份
	let year = date.getFullYear(); //getFullYear()代替getYear()
	//月 getMonth()：0 ~ 11
	let month = addZero(date.getMonth() + 1);
	//日 getDate()：(1 ~ 31)
	let day = addZero(date.getDate());
	//时 getHours()：(0 ~ 23)
	let hour = addZero(date.getHours());
	//分 getMinutes()： (0 ~ 59)
	let minute = addZero(date.getMinutes());
	//秒 getSeconds()：(0 ~ 59)
	let second = addZero(date.getSeconds());
	if (type == 'all') return year + f + month + f + day + " " + hour + ':' + minute + ':' + second;
	if (type == 'YMD') return year + f + month + f + day;
	if (type == 'hms') return hour + ':' + minute + ':' + second;
	if (type == 'm') return month;
}


const tools = {
	encode64,
	decode64,
	getDateTime
}
export default tools