import axios from "axios"
import router from '@/router/index'
import { apiUrl  } from '@/public/apiUrl'

import { Message } from 'element-ui';
const api = (data) => {
	return new Promise((resolve, reject) => {
		axios({
			url: apiUrl + data.url,
			method: data.method || 'post',
			data: data.data || '',
			withCredentials: true
		}).then(res => {
			// console.log('返回',res.data)
			if (res.data.code == 200) {
				resolve(res.data.data)
			}else{
				console.log('其他：',res)
				Message({
					message: res.data.msg,
					type: 'warning'
				});
			}
		}).catch(err => {
			console.log('请求失败：',err)
		});
	});
}

export default api
