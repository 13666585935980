import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  equipment:''// Android iOS PC
  },
  getters: {
  },
  mutations: {
	  // 获取设备
	  setEqui(state,item){
		  // console.log('vuex',item)
		  state.equipment = item
	  }
  },
  actions: {
  },
  modules: {
  }
})
