import Vue from 'vue'
import 'vant/lib/index.css';
import { Button,Swipe, SwipeItem,Tab,Tabs } from 'vant';
// 按钮
Vue.use(Button);
// 轮播图
Vue.use(Swipe);
Vue.use(SwipeItem);
// Tab 标签页
Vue.use(Tab);
Vue.use(Tabs);